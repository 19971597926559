import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { ctaBg } from "../assets";
import { Button } from "@mui/material";
import { BsDot } from "react-icons/bs";

function Copyright() {
  return (
    <>
    <Typography variant="body2">
      {"Copyright © "}
      pristavia {new Date().getFullYear()}
      {"."} All rights reserved. 
    </Typography>
   
    </>
  );
}

function PlayResponsible() {
  return (
    <Typography variant="body1" textTransform="uppercase">
    Does not involve the use of Real Money. <br/>
    This website is intended for persons over 18 years of age and is for entertainment purposes only.   
    </Typography>
  );
}

export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          backgroundColor: "primary.main"
        }}
      >
       
        <Box maxWidth="md" mx="auto" style={{textAlign: "center"}}>
        <Typography variant="h6">
        info@pristavia.com
          </Typography>
        <Typography variant="h6">
        +19024902400
          </Typography>
          <Typography variant="h6">
          26 Thomas Raddall Dr, Halifax, NS B3S 0E2, Canada          </Typography>
         
        </Box>

        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2rem 0",
            gap: "2rem",
            
          }}
        >
          <div>
          <Typography variant="body1">
            <Link
              to="/terms"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              Terms
            </Link>{" "}
            <BsDot style={{ verticalAlign: "middle" }} />{" "}
            <Link
              to="/privacy"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
          </Typography>
          </div>

          <div> <PlayResponsible />
          <Copyright /></div>
          
        </Container>
      </Box>
  );
}
