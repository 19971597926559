import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Box, Paper} from "@mui/material";

export default function About() {
  return (
    <div className="about-page" style={{ margin: "5rem", color: "white",  padding: "0 1rem" }}>
      <Container maxWidth="lg" style={{ margin: "auto" }}>
        <Typography variant="h2" gutterBottom>
          Welcome to pristavia!
        </Typography>

        <Typography variant="h5" gutterBottom>
          At pristavia, we are dedicated to offering an exceptional collection of free online games, providing endless enjoyment for players worldwide. Whether you prefer gaming solo or with friends, our platform delivers a seamless experience without the need for downloads, logins, or annoying popups. Accessible on desktop, tablet, and mobile, you can enjoy your favorite games anywhere, anytime. Every month, millions of gamers turn to pristavia as their favorite gaming hub.
        </Typography>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: "13px", backgroundColor: "primary.main500" }}>
            <Typography variant="h4" gutterBottom>
              Our Journey
            </Typography>
            <Typography variant="body1" gutterBottom>
              pristavia began as a side project fueled by a shared love for gaming and a vision to create a platform that offers an outstanding gaming experience for everyone. Our small, passionate team of gaming enthusiasts set out to build a community where gamers could find and enjoy the best games available. As we've grown, we've continuously added new games and features to keep our community engaged and entertained.
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: "13px", backgroundColor: "primary.main500" }}>
            <Typography variant="h4" gutterBottom>
              Our Team
            </Typography>
            <Typography variant="body1" gutterBottom>
              The pristavia team is a diverse group of talented individuals who share a common passion for gaming. Our developers work tirelessly to bring you the latest and greatest games, while our support staff ensures your experience on our platform is smooth and enjoyable. We foster a collaborative and innovative environment where ideas thrive and every team member's contribution is valued.
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: "13px", backgroundColor: "primary.main500" }}>
            <Typography variant="h4" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our mission at pristavia is to create the ultimate online gaming destination that is free and accessible to everyone. We are committed to offering a diverse range of games that cater to different tastes and preferences, from thrilling adventures to challenging puzzles and everything in between.
            </Typography>
          </Paper>
        </Box>

        <Typography variant="h5" gutterBottom>
          Get in Touch
        </Typography>
        <Typography variant="body1">
          We love hearing from our players and developers. If you have any questions, feedback, or if you are a game developer looking to feature your game on our platform, please reach out to us at <a href="mailto:info@pristavia.com">info@pristavia.com</a>.
        </Typography>

      </Container>
    </div>
  );
}
